<template>
  <div class="toast_pop_view" v-show="showToast">
    <div class="toast_content_view">{{ text }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      text: "",
      showToast: false
    };
  },
  methods: {
    show(tx, time = 1200) {
      this.text = tx;
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
      }, time);
    }
  }
};
</script>

<style lang="scss" scoped>
.toast_pop_view {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
}

.toast_content_view {
  position: absolute;
  left: 50%;
  top: 30%;
  background-color: rgba($color: #000000, $alpha: .7);
  padding: 10px;
  border-radius: 4px;
  color: #fff;
  max-width: 200px;
  font-size: 12px;
  transform: translateX(-50%);
}
</style>