<template>
  <div class="toast_pop_view" v-show="isShow">
    <div class="toast_content_view">
      <div class="time_tx">{{ timeNum }}s后消失</div>
      <div>代下单小程序卡片已发送给用户</div>
      <el-button size="small" type="primary" @click="stop" plain>知道了</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
      timeNum: 5
    };
  },
  beforeDestroy() {
    if (this.isShow) {
      this.stop();
    }
  },
  methods: {
    show() {
      this.timeNum = 5;
      this.isShow = true;
      this.countDownTime();
    },
    countDownTime() {
      if (this.timeNum == 0) {
        this.stop();
        this.timeOut = null;
        return;
      }
      this.timeOut = setTimeout(() => {
        this.timeNum -= 1;
        this.countDownTime();
      }, 1000);
    },
    stop() {
      if (this.timeOut) {
        clearTimeout(this.timeOut);
        this.timeOut = null;
      }
      this.isShow = false;
      this.$parent.endAction();
    }
  }
};
</script>

<style lang="scss" scoped>
.toast_pop_view {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.toast_content_view {
  position: absolute;
  left: 50%;
  top: 30%;
  background-color: #fff;
  padding-top: 10px;
  border-radius: 4px;
  color: #409EFF;
  width: 260px;
  font-size: 14px;
  transform: translateX(-50%);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  border: 1px solid var(--boder-color);

  .time_tx {
    color: #666;
    margin-bottom: 10px;
    font-size: 12px;
  }

  /deep/ .el-button {
    width: 100%;
    margin-top: 20px;
  }
}
</style>