<template>
  <div class="glass_bg_view">
    <div class="step_view">
      <template v-for="(item, index) in stepNameList">
        <img :key="`key_${index}`" v-show="index != 0" class="step_icon" :src="require('@/assets/icon_glasses_next.png')">
        <div class="name_tx" :class="`${stepIndex == index ? 'is_step_active' : ''}`" :key="`key_name_${index}`">{{ item }}</div>
      </template>
    </div>
    <!-- 选镜框镜腿 -->
    <div class="content_view" v-if="stepIndex == 0">
      <div class="frame_item_view" v-for="(item, index) in glassFrameList" :key="item.specsCateId" v-show="!item[0].suId">
        <div class="title_tx">
          <span>{{ item[0].name }} </span>
          <span class="choose_tx">（已选：{{ getSelectFrameText(index) }}）</span>
        </div>
        <div class="frame_su_list_view">
          <div class="frame_su_item_view" v-for="(suItem, idx) in item" :key="`key_${idx}`" @click="chooseFrameAction(index, idx)" v-show="parentSpecsIsSelect(suItem, idx, item)">
            <img class="img_content" :src="suItem.materialImg" alt="">
            <img v-show="selectSpecsIds[index] == suItem.specsId" class="icon_choose" :src="require('@/assets/icon_choose.png')" alt="">
          </div>
        </div>
      </div>
      <div class="bottom_view">
        <el-button size="small" type="primary" @click="nextAction">下一步</el-button>
      </div>
    </div>
    <!-- 选择度数 -->
    <div class="content_view" v-if="stepIndex == 1">
      <div class="frame_item_view">
        <!-- 度数 -->
        <div class="profile_item_view">
          <div class="tag_tx">选择度数：</div>
          <el-select v-model="glassProfileInfo.sph_left" size="small" placeholder="选择左眼度数">
            <el-option v-for="(item, index) in degreeList" :key="`key_${index}`" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <el-select v-model="glassProfileInfo.sph_right" style="margin-left: 10px;" size="small" placeholder="选择右眼度数">
            <el-option v-for="(item, index) in degreeList" :key="`key_${index}`" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </div>
        <!-- 瞳距 -->
        <div class="profile_item_view" v-show="glassProfileInfo.sph_left && glassProfileInfo.sph_right">
          <div class="tag_tx">选择瞳距：</div>
          <el-select v-model="glassProfileInfo.pd_myopia" size="small" placeholder="选择瞳距">
            <el-option v-for="(item, index) in glassPDList" :key="`key_${index}`" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </div>
        <!-- 散光 -->
        <div class="profile_item_view">
          <div class="tag_tx">选择散光：</div>
          <el-select v-model="glassProfileInfo.cyl_left" size="small" placeholder="请选择左眼散光" clearable>
            <el-option v-for="(item, index) in astigmiaList" :key="`key_${index}`" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <el-select v-model="glassProfileInfo.cyl_right" style="margin-left: 10px;" size="small" placeholder="选择右眼散光" clearable>
            <el-option v-for="(item, index) in astigmiaList" :key="`key_${index}`" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </div>
        <!-- 轴位 -->
        <div class="profile_item_view">
          <div class="tag_tx">选择轴位：</div>
          <el-select :disabled="!glassProfileInfo.cyl_left" v-model="glassProfileInfo.axis_left" size="small" placeholder="请选择左眼轴位">
            <el-option v-for="(item, index) in axialList" :key="`key_${index}`" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <el-select :disabled="!glassProfileInfo.cyl_right" v-model="glassProfileInfo.axis_right" style="margin-left: 10px;" size="small" placeholder="选择右眼轴位">
            <el-option v-for="(item, index) in axialList" :key="`key_${index}`" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </div>
      </div>
      <div class="bottom_view">
        <el-button size="small" type="info" @click="stepIndex = 0">上一步</el-button>
        <el-button size="small" @click="choosePlainAction" v-if="productInfo.glassData.isPlain == 1">购买平光</el-button>
        <el-button size="small" type="primary" @click="nextAction">下一步</el-button>
      </div>
    </div>
    <!-- 选择镜片 -->
    <div class="content_view" v-if="stepIndex == 2">
      <div class="frame_item_view" v-if="glassLensList.length">
        <div class="title_tx">镜片功能</div>
        <div class="lens_list_view">
          <div class="lens_item_view" :class="`${item.isChoose ? 'lens_is_active' : ''}`" v-for="(item, index) in glassLensList" :key="`key_${index}`" @click="changeLensCategoryAction(index)">{{ item.name }}</div>
        </div>
        <div class="title_tx">镜片规格</div>
        <div class="lens_list_view">
          <div class="lens_big_item_view" :class="`${item.specsId == selectSpecsIds[selectSpecsIds.length - 1] ? 'lens_is_active' : ''}`" v-for="(item, index) in getSelectLensList" :key="`key_${index}`" @click="lensItemAction(item)">
            <div>{{ item.jpName }}</div>
            <div>{{ item.jpDes }}</div>
          </div>
        </div>
      </div>
      <el-empty v-else description="无可选镜片，请重新选择！" :image="require('@/assets/icon_empty.png')"></el-empty>
      <div class="bottom_view">
        <el-button size="small" type="info" @click="lensLastAction">上一步</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['productInfo', 'submitVo'],
  inject: ['showToast'],
  data() {
    return {
      stepNameList: ['选择镜框和镜腿', '选择度数', '选择镜片'],
      //specs 数据数组
      glassFrameList: [],
      // 镜片信息 计算后
      glassLensList: [],
      selectSpecsIds: [],
      stepIndex: 0,
      glassProfileInfo: {
        sph_left: "",
        sph_right: "",
        pd_myopia: "",
        cyl_left: "",
        cyl_right: "",
        axis_left: "",
        axis_right: "",
        glasstype: 0
      },
      // 度数 元数组
      degreeList: [],
      // 瞳距  元数组
      glassPDList: [],
      // 散光 元数组
      astigmiaList: [],
      // 轴位 元数组
      axialList: []
    };
  },
  watch: {
    "glassProfileInfo.sph_left": function () {
      this.getGlassPDList();
    },
    "glassProfileInfo.sph_right": function () {
      this.getGlassPDList();
    },
    "glassProfileInfo.cyl_left": function (val) {
      if (!val) {
        this.glassProfileInfo.axis_left = "";
      }
    },
    "glassProfileInfo.cyl_right": function (val) {
      if (!val) {
        this.glassProfileInfo.axis_right = "";
      }
    }
  },
  computed: {
    getSelectLensList() {
      return this.glassLensList.find(o => o.isChoose).child;
    }
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      let glassData = JSON.parse(JSON.stringify(this.productInfo.glassData));
      let glassInfoMap = {};
      glassData.glassInfo.forEach(ob => {
        glassInfoMap[ob.specsId] = ob;
      });
      let sizeList = JSON.parse(JSON.stringify(this.productInfo.sizeDetail));
      let sumSkuList = [];
      for (let i = 0; i < sizeList.length; i++) {
        let su_item = sizeList[i];
        su_item.specs.sort((a, b) => a.specsCateId - b.specsCateId);
        for (let j = 0; j < su_item.specs.length; j++) {
          let item = su_item.specs[j];
          if (j != 0) {
            item.parentSpecsIds = [su_item.specs[j - 1].specsId].concat(su_item.specs[j - 1].parentSpecsIds ?? []);
          }
          if (j == su_item.specs.length - 1) {
            item.suId = su_item.suId;
          }
          if (glassInfoMap[item.specsId]) {
            item = Object.assign(item, glassInfoMap[item.specsId]);
          }
          if (!sumSkuList[j]) sumSkuList[j] = [];
          sumSkuList[j].push(item);
        }
      }
      if (sumSkuList.length > 1) {
        let spcesImgMap = {};
        for (const key in this.productInfo.sizeList) {
          this.productInfo.sizeList[key].forEach(item => {
            item.specs.forEach(ob => {
              spcesImgMap[ob.specsId] = ob.materialImg || '';
            });
          });
        }

        let keyMap = {};
        sumSkuList.forEach((item, index) => {
          if (index == 0) {
            sumSkuList[index] = item.filter(ob => {
              if (!keyMap[ob.specsId]) {
                keyMap[ob.specsId] = 1;
                ob.materialImg = spcesImgMap[ob.specsId];
                return true;
              };
              return false;
            });
          } else {
            item.forEach(ob => ob.materialImg = spcesImgMap[ob.specsId]);
          }
        });
        this.glassFrameList = sumSkuList;
        this.selectSpecsIds = sizeList.find(ob => ob.suId == this.productInfo.suData.suId).specs.reduce((s, e) => {
          s.push(e.specsId);
          return s;
        }, []);
      }
    },
    parentSpecsIsSelect(item, idx, list) {
      if (!item.parentSpecsIds) {
        return true;
      }
      for (let i = 0; i < item.parentSpecsIds.length; i++) {
        if (!this.selectSpecsIds.includes(item.parentSpecsIds[i])) {
          return false;
        }
      }
      if (list) {
        let findIndex = list.findIndex(ob => ob.specsId == item.specsId && this.selectSpecsIds.includes(ob.parentSpecsIds[0]));
        if (findIndex != idx) {
          return false;
        }
      }
      return true;
    },
    // 下一步
    nextAction() {
      if (this.stepIndex == 0) {
        this.getDegreeList();
        this.getAstigmiaList();
        this.getAxialList();
        this.glassProfileInfo = {
          sph_left: "",
          sph_right: "",
          pd_myopia: "",
          cyl_left: "",
          cyl_right: "",
          axis_left: "",
          axis_right: "",
          glasstype: 0
        };
      } else if (this.stepIndex == 1) {
        this.glassProfileInfo.glasstype = 0;
        let toastMap = {
          sph_left: "请选择左眼度数",
          sph_right: "请选择右眼度数",
          pd_myopia: "请选择瞳距",
        };
        for (const key in this.glassProfileInfo) {
          if (key == 'glasstype') {
            continue;
          }
          if (!this.glassProfileInfo[key] && toastMap[key]) {
            this.showToast(toastMap[key]);
            return;
          }
        }
        if (this.glassProfileInfo.cyl_left && !this.glassProfileInfo.axis_left) {
          this.showToast("请选择左眼轴位");
          return;
        }
        if (this.glassProfileInfo.cyl_right && !this.glassProfileInfo.axis_right) {
          this.showToast("请选择右眼轴位");
          return;
        }
        if (parseFloat(this.glassProfileInfo.sph_left) == 0 && parseFloat(this.glassProfileInfo.sph_right) == 0) {
          this.showToast(this.productInfo.glassData.isPlain == 0 ? '该眼镜不支持平光' : '您选择的度数为0，如需购买平光镜，请直接选择【购买平光】');
          return;
        }
        this.getGlassLensList();
      }
      this.stepIndex++;
    },
    // 选择平光
    choosePlainAction() {
      this.glassProfileInfo.glasstype = 1;
      this.getGlassLensList();
      this.stepIndex = 2;
    },
    // 获取度数 数组
    getDegreeList() {
      let glassRange = this.productInfo.glassData.supportRange.glassRange;
      let list = [];
      if (glassRange.hypMin === 0 && glassRange.hypMax === 0 && glassRange.myoMax === 0 &&
        glassRange.myoMin === 0) {
        if (this.productInfo.glassData.isPlain == 0) {
          list.push({ label: "平光(0.00)", value: '0' });
        }
      } else {
        let isPlainExistence = false;
        //近视
        if (glassRange.myoMax != 0) {
          for (let ds_n = glassRange.myoMax; ds_n >= glassRange.myoMin; ds_n = ds_n - 0.25) {
            let z_flag = '';
            if (ds_n > 0) {
              z_flag = "-";
            }
            let jszw = '';
            if (ds_n * 100 == 0) {
              jszw = '平光';
              isPlainExistence = true;
            } else {
              jszw = '近视' + (ds_n * 100) + '度';
            }
            list.push({ label: `${jszw}(${z_flag}${ds_n.toFixed(2)})`, value: `${ds_n == 0 ? '' : '-'}${ds_n.toFixed(2)}` });
          }
        }
        //远视
        if (glassRange.hypMax != 0) {
          for (let ds_n = glassRange.hypMin; ds_n <= glassRange.hypMax; ds_n = ds_n + 0.25) {
            let z_flag = '';
            if (ds_n > 0) {
              z_flag = "+";
            }
            let yszw = '';
            if (ds_n * 100 == 0) {
              if (isPlainExistence) {
                continue;
              } else {
                yszw = '平光';
              }
            } else {
              yszw = '远视' + (ds_n * 100) + '度';
            }
            list.push({ label: `${yszw}(${z_flag}${ds_n.toFixed(2)})`, value: ds_n.toFixed(2) });
          }
        }
      }
      this.degreeList = list;
    },
    // 获取瞳距数组
    getGlassPDList() {
      let { sph_left, sph_right } = this.glassProfileInfo;
      if (!sph_left || !sph_right) {
        this.glassPDList = [];
        this.glassProfileInfo.pd_myopia = "";
        return;
      }

      if (parseFloat(sph_left) == 0 && parseFloat(sph_right) == 0) {
        this.showToast(this.productInfo.glassData.isPlain == 0 ? '该眼镜不支持平光' : '您选择的度数为0，如需购买平光镜，请直接选择【购买平光】');
      }
      let pdData = this.productInfo.glassData.glassFrame.find(ob => ob.specsId == this.selectSpecsIds[0]).framePd;
      let { hypPDMax, hypPDMin, myoPDMax, myoPDMin } = pdData;
      let minNum = 0;
      let maxNum = 0;
      // 两个都是近视 取并集
      if (parseFloat(sph_left) <= 0 && parseFloat(sph_right) <= 0) {
        minNum = myoPDMin;
        maxNum = myoPDMax;
      } else if (parseFloat(sph_left) >= 0 && parseFloat(sph_right) >= 0) {
        // 两个都是远视  取交集
        minNum = hypPDMin;
        maxNum = hypPDMax;
      } else { //一个镜片为近视，一个镜片为远视时，瞳距下限取二者最大值，上限取二者最小值。
        minNum = Math.max(myoPDMin, hypPDMin);
        maxNum = Math.min(hypPDMax, myoPDMax);
      }
      let arr = [];
      for (let i = minNum; i <= maxNum; i++) {
        arr.push({ label: i.toString(), value: i.toString() });
      }
      if (this.glassProfileInfo.pd_myopia && (this.glassProfileInfo.pd_myopia < minNum || this.glassProfileInfo.pd_myopia > maxNum)) {
        this.glassProfileInfo.pd_myopia = "";
      }
      this.glassPDList = arr;
    },
    // 散光 数组
    getAstigmiaList() {
      let glassRange = this.productInfo.glassData.supportRange.glassRange;
      let minNum = Math.min(glassRange.astMax, glassRange.astMin);
      let maxNum = Math.max(glassRange.astMax, glassRange.astMin);
      let list = [];
      for (let sg_n = minNum; sg_n <= maxNum; sg_n = sg_n + 0.25) {
        if (sg_n == 0) {
          continue;
        }
        list.push({ label: `散光${sg_n * 100}度(-${sg_n.toFixed(2)})`, value: `-${sg_n.toFixed(2)}` });
      }
      this.astigmiaList = list;
    },
    // 轴位 数组
    getAxialList() {
      let list = [];
      for (let zw_n = 0; zw_n <= 180; zw_n++) {
        list.push({ label: zw_n.toString(), value: zw_n.toString() });
      }
      this.axialList = list;
    },
    // 获取 镜片数据
    getGlassLensList() {
      let list = this.glassFrameList.slice(-1).shift().filter(item => {
        if (!this.parentSpecsIsSelect(item)) {
          return false;
        }
        let { sph_left, sph_right, cyl_left, cyl_right, axis_left, axis_right, glasstype } = this.glassProfileInfo;
        if (glasstype == 1) {
          return item.isPlain == 1;
        }
        const v = item.glassNum;
        let max_a = Math.max(v.astMax, v.astMin);
        let min_a = Math.min(v.astMax, v.astMin);
        let isCanShow = true;
        if (cyl_left && cyl_left != 0) {
          if (Math.abs(cyl_left) > max_a || Math.abs(cyl_left) < min_a) {
            isCanShow = false;
          }
        }

        if (cyl_right && cyl_right != 0) {
          if (Math.abs(cyl_right) > max_a || Math.abs(cyl_right) < min_a) {
            isCanShow = false;
          }
        }

        if (axis_left && axis_left != -1) {
          if (axis_left > v.axiMax || axis_left < v.axiMin) {
            isCanShow = false;
          }
        }
        if (axis_right && axis_right != -1) {
          if (axis_right > v.axiMax || axis_right < v.axiMin) {
            isCanShow = false;
          }
        }

        if (sph_left <= 0) { //近视
          if (sph_left > -v.myoMin || sph_left < -v.myoMax) {
            isCanShow = false;
          }
        }
        if (sph_left > 0) { //远视
          if (sph_left > v.hypMax || sph_left < v.hypMin) {
            isCanShow = false;
          }
        }

        if (sph_right <= 0) { //近视
          if (sph_right > -v.myoMin || sph_right < -v.myoMax) {
            isCanShow = false;
          }
        }
        if (sph_right > 0) { //远视
          if (sph_right > v.hypMax || sph_right < v.hypMin) {
            isCanShow = false;
          }
        }
        //如果筛选出可用镜片
        if (isCanShow && sph_left * sph_right < 0 && item.isSpecial != 1) { // 特殊类型，一个近视一个远视
          isCanShow = false;
        }
        return isCanShow;
      });
      let map = {};
      list.forEach(item => {
        if (!map[item.glassLabelName]) {
          map[item.glassLabelName] = [];
        }
        map[item.glassLabelName].push(item);
      });
      let arr = [];
      for (const key in map) {
        arr.push({ name: key, child: map[key], isChoose: false });
      }
      if (arr.length) {
        arr[0].isChoose = true;
        this.glassLensList = JSON.parse(JSON.stringify(arr));
        let normalSu = this.glassLensList[0].child[0];
        this.selectSpecsIds.push(normalSu.specsId);
        this.setSelectSuId(normalSu.suId);
      }
    },
    getSelectFrameText(index) {
      let item = this.glassFrameList[index].find(ob => ob.specsId == this.selectSpecsIds[index]);
      if (!item) {
        return "无";
      }
      return item.des;
    },
    chooseFrameAction(index, idx) {
      let specsId = this.glassFrameList[index][idx].specsId;
      if (specsId == this.selectSpecsIds[index]) {
        return;
      }
      this.selectSpecsIds.splice(index, 1, specsId);
    },
    changeLensCategoryAction(index) {
      if (this.glassLensList[index].isChoose) {
        return;
      }
      this.glassLensList = this.glassLensList.map((item, i) => {
        if (index == i) {
          this.selectSpecsIds.splice(this.selectSpecsIds.length - 1, 1, item.child[0].specsId);
          this.setSelectSuId(item.child[0].suId);
          item.isChoose = true;
        } else {
          item.isChoose = false;
        }
        return item;
      });
    },
    lensItemAction(item) {
      if (this.selectSpecsIds[this.selectSpecsIds.length - 1] == item.specsId) {
        return;
      }
      this.selectSpecsIds.splice(this.selectSpecsIds.length - 1, 1, item.specsId);
      this.setSelectSuId(item.suId);
    },
    lensLastAction() {
      this.$parent.submitVo.suId = "";
      this.$parent.submitVo.discountInfo = null;
      this.stepIndex = 1;
      this.selectSpecsIds.pop();
    },
    setSelectSuId(suId) {
      if (!suId) {
        return;
      }
      let obj = this.productInfo.sizeDetail.find(o => o.suId == suId);
      if (obj.storeStatus == 0) {
        this.showToast('原材料不足，请重新选择！');
        return;
      }
      this.$parent.submitVo.suId = suId;
      this.$parent.submitVo.profileInfo = JSON.parse(JSON.stringify(this.glassProfileInfo));
    }
  }
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 1px;
}

.glass_bg_view {
  height: 350px;
  border-bottom: 1px solid var(--boder-color);
  position: relative;
  margin-top: 10px;
}

.step_view {
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: center;
  background-color: var(--boder-color);
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;

  .name_tx {
    font-size: 14px;
    color: #333;
  }

  .step_icon {
    width: 10px;
    height: 10px;
    margin: 0 30px;
  }

  .is_step_active {
    color: #409EFF;
  }
}

.content_view {
  height: calc(100% - 90px);
  overflow-y: auto;
}

.frame_item_view {
  padding-top: 10px;

  .title_tx {
    font-size: 14px;
    color: #333;
    margin-left: 10px;
    margin-top: 10px;
  }

  .choose_tx {
    color: #666;
  }

  .frame_su_list_view {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 10px;

    .frame_su_item_view {
      height: 40px;
      width: 40px;
      position: relative;
      border-radius: 4px;
      overflow: hidden;
      font-size: 0;
      cursor: pointer;
      margin: 8px 0 0 8px;

      .img_content {
        width: 100%;
        height: 100%;
      }

      .icon_choose {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 20px;
        height: 20px;
      }
    }
  }

  .profile_item_view {
    display: flex;
    align-items: center;
    padding: 10px;

    .tag_tx {
      font-size: 14px;
      color: #666;
      width: 70px;
      flex-shrink: 0;
    }

    /deep/ .el-select {
      width: 100%;
    }
  }

  .lens_list_view {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .lens_item_view {
      border-radius: 4px;
      padding: 0 10px;
      height: 24px;
      line-height: 24px;
      margin: 8px 0 0 10px;
      font-size: 12px;
      color: #999;
      cursor: pointer;
      border: 1px solid #999;
    }

    .lens_big_item_view {
      border-radius: 4px;
      padding: 0 10px;
      line-height: 20px;
      margin: 8px 10px 0 10px;
      font-size: 12px;
      color: #999;
      cursor: pointer;
      border: 1px solid #999;
      width: 100%;
      text-align: justify;
      max-width: 400px;
    }

    .lens_is_active {
      border-color: #409EFF;
      color: #409EFF;
    }
  }
}

.bottom_view {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  justify-content: center;

  /deep/ .el-button {
    width: 100%;
    max-width: 400px;
  }
}
</style>