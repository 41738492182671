<template>
  <div>
    <div class="item_view" v-for="(item, index) in sumSkuList" :key="`key_${index}`">
      <div class="title_tx">{{ item[0].name }}：</div>
      <div class="list_sku_view">
        <div class="sku_item_view" :class="getIsActive(skuItem)" v-for="(skuItem, idx) in item" :key="`id_${idx}`" v-show="parentSpecsIsSelect(skuItem)" @click="skuClick(index, idx)">
          <span>{{ skuItem.des }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['productInfo', 'submitVo'],
  data() {
    return {
      sumSkuList: [],
      selectSpecsIds: []
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      let sizeList = JSON.parse(JSON.stringify(this.productInfo.sizeDetail)).filter(o => o.storeStatus == 1);
      let sumSkuList = [];
      for (let i = 0; i < sizeList.length; i++) {
        let su_item = sizeList[i];
        su_item.specs.sort((a, b) => a.specsCateId - b.specsCateId);
        for (let j = 0; j < su_item.specs.length; j++) {
          let item = su_item.specs[j];
          if (j != 0) {
            item.parentSpecsIds = [su_item.specs[j - 1].specsId].concat(su_item.specs[j - 1].parentSpecsIds ?? []);
          }
          if (j == su_item.specs.length - 1) {
            item.suId = su_item.suId;
          }
          if (!sumSkuList[j]) sumSkuList[j] = [];
          sumSkuList[j].push(item);
        }
      }
      if (sumSkuList.length > 1) {
        let keyMap = {};
        sumSkuList[0] = sumSkuList[0].filter(item => {
          if (keyMap[item.specsId]) return false;
          keyMap[item.specsId] = 1;
          return true;
        });
      }
      this.sumSkuList = sumSkuList;
      let normalSuId = this.productInfo.suData.suId;
      let findOb = sizeList.find(o => o.suId == normalSuId);
      if (!findOb) {
        findOb = sizeList[0];
      }
      this.selectSpecsIds = findOb.specs.reduce((s, e) => {
        s.push(e.specsId);
        return s;
      }, []);
      this.$parent.submitVo.suId = findOb.suId;
    },
    getIsActive(item) {
      return this.selectSpecsIds.indexOf(item.specsId) != -1 ? 'is_active' : '';
    },
    parentSpecsIsSelect(item) {
      if (!item.parentSpecsIds) {
        return true;
      }
      for (let i = 0; i < item.parentSpecsIds.length; i++) {
        if (!this.selectSpecsIds.includes(item.parentSpecsIds[i])) {
          return false;
        }
      }
      return true;
    },
    skuClick(moduleIdx, suIdx) {
      let selectOb = this.sumSkuList[moduleIdx][suIdx];
      if (this.selectSpecsIds[moduleIdx] == selectOb.specsId) {
        return;
      }
      this.selectSpecsIds.splice(moduleIdx, 1, selectOb.specsId);
      let seletSuId = '';
      if (selectOb.suId) {
        seletSuId = selectOb.suId;
      } else {
        for (let i = moduleIdx + 1; i < this.selectSpecsIds.length; i++) {
          let list = this.sumSkuList[i].filter(o => this.parentSpecsIsSelect(o));
          let findOb = list.find(o => o.specsId == this.selectSpecsIds[i]) || list[0];
          this.selectSpecsIds.splice(i, 1, findOb.specsId);
          if (findOb.suId) seletSuId = findOb.suId;
        }
      }
      this.$parent.submitVo.suId = seletSuId;
    }
  }
};
</script>

<style lang="scss" scoped>
.item_view {
  padding: 10px;

  .title_tx {
    font-size: 14px;
    font-weight: bold;
    color: #333;
    margin-left: 8px;
  }

  .list_sku_view {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .sku_item_view {
      border-radius: 4px;
      padding: 0 10px;
      height: 20px;
      line-height: 20px;
      margin: 8px 0 0 8px;
      font-size: 12px;
      color: #999;
      cursor: pointer;
      border: 1px solid #999;
    }

    .is_active {
      border-color: #409EFF;
      color: #409EFF;
    }
  }
}
</style>