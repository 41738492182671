<template>
  <div class="discoun_bg_view">
    <div class="title_tx">优惠下单</div>
    <div class="item_view">
      <div class="empty_tx" v-if="!selectDiscounInfo || selectDiscounInfo.userDiscount.length == 0">暂无优惠</div>
      <div class="card_list_view" v-else>
        <div class="card_item" v-for="(item, index) in selectDiscounInfo.userDiscount" :key="`left_key_${index}`">
          <el-radio :value="chooseDiscountInfo.leftIdx == index ? 1 : 0" :label="1" @input="itemClick('left', index)">
            <span>{{ ["参团卡：", "津贴：", "返现卡："][item.type - 1] }}</span>
            <span>-{{ item.priceStr }}元</span>
          </el-radio>
          <div class="best_tx" v-show="item.priceStr >= chooseDiscountInfo.leftBestPrice">最佳优惠</div>
        </div>
      </div>
      <div class="bottom_view">
        <el-button size="small" type="primary" :disabled="(chooseDiscountInfo.leftIdx == -1 && chooseDiscountInfo.rightIdx != -1) || !submitVo.suId" @click="sendOrderAction('left')">
          <span v-if="selectDiscounInfo">{{ getButtonText('left', chooseDiscountInfo.leftIdx == -1 ? chooseDiscountInfo.leftBestPrice : selectDiscounInfo.userDiscount[chooseDiscountInfo.leftIdx].priceStr) }}</span>
          <span v-else>{{ getButtonText('left', '0') }}</span>
        </el-button>
      </div>
    </div>
    <div style="width: 1px;margin: 0 4px;background-color: var(--boder-color);"></div>
    <div class="item_view">
      <div class="empty_tx" v-if="!selectDiscounInfo || selectDiscounInfo.sendDiscount.length == 0">暂无优惠</div>
      <div class="card_list_view" v-else>
        <div class="card_item" v-for="(item, index) in selectDiscounInfo.sendDiscount" :key="`right_key_${index}`">
          <el-radio :value="chooseDiscountInfo.rightIdx == index ? 1 : 0" :label="1" @input="itemClick('right', index)">
            <span>{{ ["参团卡：", "津贴：", "返现卡："][item.type - 1] }}</span>
            <span>-{{ item.priceStr }}元</span>
          </el-radio>
          <div v-if="item.type == 2 && chooseDiscountInfo.rightIdx == index" class="price_change_view">
            <span>补发津贴</span>
            <el-input-number size="mini" :value="item.priceStr" @change="rangeChange($event, item)" :min="1" :max="item.allowanceOnePrice * submitVo.num" :step="1" :controls="false" />
            <span>元</span>
          </div>
          <div class="best_tx" v-show="(chooseDiscountInfo.rightIdx != index || !item.needSend) && item.priceStr >= chooseDiscountInfo.rightBestPrice">最佳优惠</div>
          <div class="best_tx" v-show="item.needSend && chooseDiscountInfo.rightIdx == index && item.type != 2">需要下发</div>
        </div>
      </div>
      <div class="bottom_view">
        <el-button size="small" type="primary" :disabled="(chooseDiscountInfo.rightIdx == -1 && chooseDiscountInfo.leftIdx != -1) || !submitVo.suId" @click="sendOrderAction('right')">
          <span v-if="selectDiscounInfo">{{ getButtonText('right', chooseDiscountInfo.rightIdx == -1 ? chooseDiscountInfo.rightBestPrice : selectDiscounInfo.sendDiscount[chooseDiscountInfo.rightIdx].priceStr) }}</span>
          <span v-else>{{ getButtonText('right', '0') }}</span>
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/common/axios';
import Trace from '@/common/trace';
export default {
  props: ["submitVo", "productInfo"],
  inject: ['showToast'],
  data() {
    return {
      discountMap: {},
      showErrorMsg: "",
      selectDiscounInfo: null,
      chooseDiscountInfo: {
        leftIdx: -1,
        rightIdx: -1,
        leftBestPrice: "0",
        rightBestPrice: "0"
      },
      leftButtonText: "用户自有资产可减${x}元",
      rightButtonText: "发放福利可减${x}元"
    };
  },
  computed: {
    getButtonText() {
      return (type, text) => {
        if (!text) {
          text = 0;
        }
        return type == 'left' ? this.leftButtonText.replace("${x}", text) : this.rightButtonText.replace("${x}", text);
      };
    }
  },
  watch: {
    "submitVo.suId": function () {
      this.skuChooseChange();
    },
    "submitVo.num": function () {
      this.skuNumChange();
    },
    chooseDiscountInfo: {
      handler: function (val) {
        this.changeDiscountPrice();
      },
      deep: true
    }
  },
  mounted() {
    this.initData();
    this.getCmsConfig();
  },
  methods: {
    initData() {
      if ([1, 2, 3].includes(this.productInfo.productInfo.productType)) {
        return;
      }
      let suIds = this.$parent.productInfo.sizeDetail.reduce((prev, item) => {
        if (item.storeStatus == 1) {
          prev[item.suId] = item.priceStr;
        }
        return prev;
      }, {});
      API({
        // url: "https://mock.apifox.com/m1/4186304-0-default/discount",
        url: "/coupon/calculateProductDiscountAmount.do",
        data: {
          uid: this.$parent.uid,
          suIds
        }
      }).then(res => {
        if (res.success == 1) {
          this.discountMap = res.data.suDiscountDetailDtos.reduce((preMap, item) => {
            preMap[item.skuId] = item;
            return preMap;
          }, {});
          this.skuChooseChange();
        } else {
          this.showToast(res.errMsg || "获取优惠失败，请重试！");
        }
      });
    },
    getCmsConfig() {
      API({
        url: "/material/getTextByIds.do?ids=" + "11850206,11850205",
        isGet: true
      }).then(res => {
        if (res.success == 1) {
          if (res.data['11850205']) {
            this.leftButtonText = res.data['11850205'];
          }
          if (res.data['11850206']) {
            this.rightButtonText = res.data['11850206'];
          }
        }
      });
    },
    skuChooseChange() {
      // type  1参团卡  2津贴  3返现
      let suId = this.submitVo.suId;
      let obj = this.discountMap[suId];
      this.$parent.submitVo.discountInfo = null;
      if (!suId || !obj) {
        this.selectDiscounInfo = null;
        return;
      }
      obj = JSON.parse(JSON.stringify(obj));
      let maxNum = 0;
      let maxIdx = -1;
      let userTypesMap = {};
      obj.userDiscount.forEach((item, index) => {
        let priceNum = item.discountPrice;
        if (item.type == 2) {
          priceNum = Math.min(item.discountPrice, item.allowanceOnePrice * this.submitVo.num);
        }
        if (priceNum > maxNum) {
          maxNum = priceNum;
          maxIdx = index;
        }
        userTypesMap[item.type] = item;
        item.priceStr = priceNum;
      });
      if (maxIdx != -1) {
        this.chooseDiscountInfo.leftIdx = maxIdx;
        this.chooseDiscountInfo.leftBestPrice = maxNum;
      }
      maxNum = 0;
      maxIdx = -1;
      obj.sendDiscount.forEach((item, index) => {
        let priceNum = item.discountPrice;
        if (item.type == 2) {
          priceNum = item.allowanceOnePrice * this.submitVo.num;
        }
        if (priceNum > maxNum) {
          maxNum = priceNum;
          maxIdx = index;
        }
        if (userTypesMap[item.type] && item.type != 2) {
          item.needSend = false;
          item.cardId = userTypesMap[item.type].cardId;
        } else {
          item.needSend = true;
        }
        item.priceStr = priceNum;
      });
      if (maxIdx != -1) {
        this.chooseDiscountInfo.rightIdx = obj.userDiscount.length == 0 ? maxIdx : -1;
        this.chooseDiscountInfo.rightBestPrice = maxNum;
      }
      this.selectDiscounInfo = obj;
      this.changeDiscountPrice();
    },
    skuNumChange() {
      let num = this.submitVo.num;
      if (!this.selectDiscounInfo) {
        return;
      }
      let maxNum = 0;
      let maxIdx = -1;
      this.selectDiscounInfo.userDiscount.forEach((item, index) => {
        let priceNum = item.discountPrice;
        if (item.type == 2) {
          priceNum = Math.min(item.discountPrice, item.allowanceOnePrice * num);
        }
        if (priceNum > maxNum) {
          maxNum = priceNum;
          maxIdx = index;
        }
        item.priceStr = priceNum;
      });
      if (maxIdx != -1) {
        let obj = this.selectDiscounInfo.userDiscount[maxIdx];
        this.chooseDiscountInfo.leftBestPrice = obj.priceStr;
      }
      maxNum = 0;
      maxIdx = -1;
      this.selectDiscounInfo.sendDiscount.forEach((item, index) => {
        let priceNum = item.discountPrice;
        if (item.type == 2) {
          priceNum = item.allowanceOnePrice * this.submitVo.num;
        }
        if (priceNum > maxNum) {
          maxNum = priceNum;
          maxIdx = index;
        }
        item.priceStr = priceNum;
      });
      if (maxIdx != -1) {
        let obj = this.selectDiscounInfo.sendDiscount[maxIdx];
        this.chooseDiscountInfo.rightBestPrice = obj.priceStr;
      }
    },
    itemClick(type, index) {
      if (type == 'left') {
        this.chooseDiscountInfo.leftIdx = index;
        this.chooseDiscountInfo.rightIdx = -1;
      } else {
        this.chooseDiscountInfo.leftIdx = -1;
        this.chooseDiscountInfo.rightIdx = index;
      }
      let findObj = this.selectDiscounInfo.sendDiscount.find(ob => ob.type == 2);
      if (findObj) {
        findObj.priceStr = findObj.allowanceOnePrice * this.submitVo.num;
      }
      Trace({
        operationType: 16
      });
    },
    sendOrderAction(type) {
      this.$parent.sendOrderAction();
      Trace({
        operationType: type == 'left' ? 17 : 18
      });
    },
    changeDiscountPrice() {
      let params = {
        type: -1,
        isSend: 0,
        allowancePrice: 0,
        cardId: "",
        scene: "placeorders"
      };
      let { leftIdx, rightIdx } = this.chooseDiscountInfo;
      if ((leftIdx == -1 && rightIdx == -1) || !this.selectDiscounInfo) {
        this.$parent.submitVo.discountInfo = null;
        return;
      }
      let d_vo;
      if (leftIdx != -1) {
        d_vo = this.selectDiscounInfo.userDiscount[leftIdx];
        params.isSend = 0;
      } else {
        d_vo = this.selectDiscounInfo.sendDiscount[rightIdx];
        params.isSend = d_vo.needSend ? 1 : 0;
      }
      params.cardId = d_vo.cardId || "";
      params.type = d_vo.type;
      params.allowancePrice = d_vo.priceStr;
      this.$parent.submitVo.discountInfo = params;
      if (d_vo.type != 2) {
        this.$parent.submitVo.num = 1;
      }
    },
    rangeChange(e, item) {
      item.priceStr = e || 1;
      this.changeDiscountPrice();
    }
  }
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 0;
}

.discoun_bg_view {
  display: flex;
  padding-top: 30px;
  position: relative;

  .title_tx {
    width: 100%;
    height: 30px;
    left: 0;
    top: 0;
    position: absolute;
    text-align: center;
    line-height: 30px;
    background-color: #F2F6FC;
  }

  .item_view {
    width: 50%;
    height: 160px;
    padding-bottom: 40px;
    position: relative;
    box-sizing: border-box;

    .empty_tx {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #666;
    }

    .card_list_view {
      height: 100%;
      overflow-y: auto;
    }

    .card_item {
      margin: 0 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 30px;
      flex-wrap: wrap;

      .price_change_view {
        display: flex;
        align-items: center;
        font-size: 12px;
        margin-left: 4px;

        /deep/ .el-input-number {
          margin: 0 4px;
          width: auto;
          max-width: 80px;
        }
      }

      &:first-child {
        padding-top: 10px;
      }

      /deep/ .el-radio {
        flex-shrink: 0;
        margin-right: 0;
      }

      .best_tx {
        color: #F56C6C;
        font-size: 12px;
      }
    }

    .bottom_view {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;

      /deep/ .el-button {
        width: 100%;
      }
    }
  }
}
</style>