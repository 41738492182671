<template>
  <div class="bg_view" v-loading="showLoading">
    <template v-if="productInfo">
      <!-- 商品信息 -->
      <productView :productInfo="productInfo" :submitVo="submitVo"></productView>
      <!-- 普通商品编辑器 -->
      <normalModel v-if="!productInfo.glassData" :productInfo="productInfo" :submitVo="submitVo"></normalModel>
      <!-- 眼镜编辑器 -->
      <glassModel v-else :productInfo="productInfo" :submitVo="submitVo"></glassModel>
      <!-- 购买数量 -->
      <div class="num_view" v-if="!productInfo.glassData">
        <span>购买数量</span>
        <el-input-number size="mini" v-model="submitVo.num" :min="1" :max="(submitVo.discountInfo && submitVo.discountInfo.type != 2) ? 1 : 99" label="描述文字" @focus="suNumInputFocus" @change="suNumInputChange"></el-input-number>
        <span>限购{{ (submitVo.discountInfo && submitVo.discountInfo.type != 2) ? "1" : "99" }}件</span>
      </div>
      <!-- 发票信息组件 -->
      <receiptPop ref="receiptPop" :isQWWeb="isQWWeb"></receiptPop>
      <!-- 备注内容组件 -->
      <remarksTx></remarksTx>
      <!-- 优惠组件 -->
      <discountView :submitVo="submitVo" :productInfo="productInfo"></discountView>
      <!-- 弹窗提示成功 -->
      <successToast ref="successToast"></successToast>
    </template>
    <el-empty v-if="!uid || !suId" :description="`${!uid ? '该用户未绑定uid' : '请选择用户想要下单的商品'}`" :image="require('@/assets/icon_empty.png')"></el-empty>
    <toast ref="toast"></toast>
    <div style="height:30px"></div>
  </div>
</template>

<script>
import API from '@/common/axios';
import receiptPop from './components/receiptPop.vue';
import productView from './components/productView.vue';
import normalModel from './components/normalModel.vue';
import glassModel from './components/glassModel.vue';
import toast from './components/toast.vue';
import remarksTx from './components/remarksTx.vue';
import discountView from './components/discountView.vue';
import successToast from './components/successToast.vue';
import Trace from '@/common/trace';
import * as ww from '@wecom/jssdk';
export default {
  props: ['suId', 'uid', 'selectUserInfo', 'isQWWeb'],
  components: {
    productView,
    normalModel,
    glassModel,
    receiptPop,
    toast,
    remarksTx,
    discountView,
    successToast
  },
  provide() {
    return {
      showToast: (msg) => {
        this.$refs.toast.show(msg);
      }
    };
  },
  data() {
    return {
      // 接口获取商品信息
      productInfo: null,
      //已选数据
      submitVo: {
        // 已选的skuId
        suId: "",
        // 已选优惠信息
        discountInfo: null,
        // 备注信息
        remarksTx: "",
        //数量
        num: 1,
        // 眼镜已选信息
        profileInfo: null,
      },
      showLoading: false
    };
  },
  mounted() {
    if (this.uid && this.suId) {
      this.initData();
    }
  },
  methods: {
    // 初始化获取商品数据
    initData() {
      this.showLoading = true;
      API({
        // url: "https://mock.apifox.com/m1/4186304-0-default/getproductInfo",
        url: "/production/info/rasterEditor.do",
        data: {
          suId: this.suId,
          editorType: 1,
          uid: this.uid
        }
      }).then(res => {
        if (res.success == 1) {
          this.productInfo = res.data;
        } else {
          this.$refs.toast.show(res.errMsg);
          setTimeout(() => {
            this.endAction();
          }, 1200);
        }
      }).catch(_ => {
        this.$refs.toast.show("获取数据失败，请检查网络连通性！");
      }).finally(_ => {
        this.showLoading = false;
      });
    },
    suNumInputFocus() {
      this.isEditSuNum = true;
    },
    suNumInputChange(currentValue, oldValue) {
      if (currentValue == oldValue) {
        return;
      }
      let type;
      if (this.isEditSuNum) {
        type = 'update';
        this.isEditSuNum = false;
      } else {
        type = currentValue > oldValue ? 'add' : 'reduce';
      }
      Trace({
        operationType: 19,
        extraData: JSON.stringify({ type })
      });
    },
    clearData() {
      this.productInfo = null;
      this.submitVo = {
        // 已选的skuId
        suId: "",
        // 已选优惠信息
        discountInfo: null,
        // 备注信息
        remarksTx: "",
        //数量
        num: 1,
        // 眼镜已选信息
        profileInfo: null,
      };
    },
    endAction() {
      this.clearData();
      this.$parent.suId = "";
    },
    // 下单
    async sendOrderAction() {
      if (!this.submitVo.suId) {
        return;
      }
      if (!this.isQWWeb) {
        const res = await API({
          url: '/xbj/status.do',
          data: {
            xbjId: this.selectUserInfo.xbjId
          },
        });
        if (res.data != 1) {
          this.$refs.toast.show('许愿不在线，请查看对应企微是否登录!', 2000);
          return;
        }
      }
      let params = { discountInfoVO: this.submitVo.discountInfo };
      params.orderBaseInfoVO = {
        suId: this.submitVo.suId,
        priceStr: this.productInfo.sizeDetail.find(o => o.suId == this.submitVo.suId).priceStr,
        num: this.submitVo.num,
      };
      params.profileInfo = this.submitVo.profileInfo;
      params.remarksTx = this.submitVo.remarksTx;
      let userInfo = JSON.parse(localStorage.getItem("accountInfo") || "{}");
      params.uid = this.uid;
      params.xbjId = this.selectUserInfo.xbjId;
      params.nickName = userInfo.nickname || "";
      params.receiptInfo = this.$refs.receiptPop.invoiceId ? { id: this.$refs.receiptPop.invoiceId || "" } : null;
      API({
        // url: "https://mock.apifox.com/m2/4186304-0-default/176217912",
        url: "/order/submitPrefillOrderInfo.do",
        data: params
      }).then(res => {
        if (res.success == 1) {
          let path = 'pages/products/products.html';
          let isCoffee = [1, 2, 3].includes(this.productInfo.productInfo.productType);
          if (!isCoffee) {
            path += `?suId=${this.submitVo.suId}&sourceFrom=placeOrder&substituteId=${res.data}&unionId=${this.selectUserInfo.unionId}&uid=${this.uid}`;
          } else {
            path += `?suId=${this.submitVo.suId}&ignoreBusiness=1`;
          }
          path += `&utm=${encodeURIComponent(`share-dxd.wx-dxd.2.500015.${userInfo.employee_id || ""}.${this.selectUserInfo.xbjId}.${this.productInfo.productId}`)}`;
          let weappMessageVo = {
            miniProgrameName: '必要商城 Biyao',
            imgUrl: this.productInfo.miniShareImg || this.productInfo.imgList[0].smallImg,
            miniProgrameTitle: this.productInfo.shareProductName,
            path: path,
            type: "miniprogram"
          };
          if (this.isQWWeb) {
            ww.sendChatMessage({
              msgtype: weappMessageVo.type,
              enterChat: false,
              miniprogram : {
                appid: "wx741fc9e9b81dad03",
                title: weappMessageVo.miniProgrameTitle,
                page: weappMessageVo.path,
                imgUrl: weappMessageVo.imgUrl
              },
              success: () => {
                this.$refs.successToast.show();
              },
              fail: (error) => {
                this.$message.error('消息发送失败-', error);
              }
            });
          } else {
            this.$parent.$parent.$refs.contentView.sendMessage([weappMessageVo]);
          }
        } else if (res.error && res.error.code == '1300001') {
          this.$refs.toast.show(res.errMsg);
          this.clearData();
          setTimeout(() => {
            this.initData();
          }, 1200);
        } else {
          this.$refs.toast.show(res.errMsg);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.bg_view {
  height: 100%;
  overflow-y: auto;
  width: 100%;
}

.num_view {
  font-size: 14px;
  color: #333;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid var(--boder-color);

  /deep/ .el-input-number {
    margin: 0 10px;
  }
}
</style>