<template>
  <div class="content_view">
    <div class="debug_view" @click="debugClick"></div>
    <el-page-header @back="goBack" content="代下单" title="返回AI中心"></el-page-header>
    <div class="product_view">
      <orderView v-if="selectUserInfo.unionId" :uid="uid" :suId="suId" :selectUserInfo="selectUserInfo" :isQWWeb="true" :key="`order_${suId}`"></orderView>
    </div>
  </div>
</template>

<script>
import orderView from "@/pages/chat/components/webContentView/components/createOrder";
import Trace from '@/common/trace';
import API from "@/common/axios";
import * as ww from '@wecom/jssdk';
export default {
  components: {
    orderView
  },
  data() {
    return {
      appId: "ww8c91222f5023cad4",
      agentId: "1000047",
      selectUserInfo: { xbjId: "", externalUserid: "", unionId: "" },
      uid: "",
      suId: "",
      showLoading: false
    };
  },
  mounted() {
    this.suId = this.$route.query.suId || "";
    this.init();
  },
  methods: {
    // 初始化获取授权信息
    init() {
      if (!this.$route.query.code) {
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appId}&agentid=${this.agentId}&redirect_uri=${encodeURIComponent(window.location.href)}&response_type=code&scope=snsapi_base&state=#wechat_redirect`;
        return;
      }
      this.showLoading = true;
      API({
        url: "/wechat/getUserByCode.do",
        data: {
          url: window.location.href,
          code: this.$route.query.code,
        }
      }).then(res => {
        if (res.success == 1) {
          this.selectUserInfo.xbjId = res.data.userId;
          this.initQWAuth(res.data);
        } else {
          this.$message.error(res.errMsg || '授权信息获取失败！');
          this.showLoading = false;
        }
      }).catch(_ => this.showLoading = false);
    },
    // 企微授权
    initQWAuth(data) {
      ww.register({
        corpId: this.appId,
        agentId: this.agentId,
        jsApiList: ['sendChatMessage', 'getCurExternalContact'],
        getAgentConfigSignature: function () {
          return { ...data, nonceStr: data.noncestr };
        },
        onAgentConfigSuccess: _ => {
          this.getCurExternalContact();
          this.showLoading = false;
        },
        onAgentConfigFail: _ => {
          this.$message.error('企微授权失败');
          this.showLoading = false;
        }
      });
    },
    // 获取外部联系人ID
    getCurExternalContact() {
      ww.getCurExternalContact({
        success: res => {
          this.selectUserInfo.externalUserid = res.userId;
          this.getUid();
        },
        fail: () => {
          this.$message.error('获取外部联系人ID失败');
        },
      });
    },
    // 获取用户ID
    getUid() {
      API({
        url: "/wechat/getUserByExternalUserId.do",
        data: {
          externalUserId: this.selectUserInfo.externalUserid
        }
      }).then(res => {
        if (res.success == 1) {
          this.selectUserInfo.unionId = res.data.unionId;
          this.uid = res.data.uid;
          Trace.selectUserInfo = { ...this.selectUserInfo };
        }
      });
    },
    goBack() {
      window.open('http://ai.biyao.com/?x-ai-sk=a9422040e5efb91a7b5c6f42f9440275&isChatIframe=1&source=placeOrder', '_self');
    },
    debugClick() {
      if (!this.debugIndex) {
        this.debugIndex = 1;
      }
      if (this.debugIndex < 5) {
        this.debugIndex += 1;
      } else {
        this.debugIndex = 1;
        this.$prompt('输入skuId', '当前debug模式', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({ value }) => {
          if (value) {
            this.suId = value;
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.content_view {
  height: 100%;
  width: 100%;
  background-color: white;
}

.product_view {
  width: 100%;
  height: calc(100% - 44px);
  position: relative;
}

/deep/ .el-page-header {
  background-color: white;
  height: 44px;
  align-items: center;
  padding-left: 15px;
  border-bottom: 1px solid var(--boder-color);
  box-sizing: border-box;
  position: relative;
}

.debug_view {
  width: 40px;
  height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 200;
}

/deep/ .el-page-header__content {
  font-size: 16px;
  font-weight: bold;
}

.error_tx {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 20px;
  color: #409eff;
}
</style>

<style>

.el-message-box {
  max-width: 100%;
}

.el-message {
  max-width: 100%;
  min-width: 100px;
}
</style>