<template>
  <div @click.capture="dialogClick">
    <div class="select_title_tx">
      <span>发票信息</span>
      <span class="content_tx">
        <span>{{ this.invoiceTitleLable ? this.invoiceTitleLable : "不开发票" }}</span>
        <i class="el-icon-edit-outline" @click="showModal"></i>
      </span>
    </div>
    <el-dialog title="" :visible.sync="show" :width="isQWWeb ? '90%' : '30%'" :before-close="handleClose">
      <div class="modal-content">
        <el-button-group>
          <el-button size="medium" ref="personalBtn" @click="typeChange('0')" class="btn-cornner" :type='isPersonalFoucus ? "primary" : "plain"'>个人
          </el-button>
          <el-button size="medium" ref="companyBtn" @click="typeChange('1')" class="btn-cornner" :type='!isPersonalFoucus ? "primary" : "plain"' style="margin-left: 20px">公司
          </el-button>
        </el-button-group>
        <el-form v-if="invoiceInfo.invoiceType == 1" class="form-class" :label-position="labelPosition" ref="company" label-width="80px" :rules="rules" :model="invoiceInfo">
          <el-form-item label="名称" prop="invoiceTitle">
            <div class="company-input-item">
              <el-autocomplete v-model.trim="invoiceInfo.invoiceTitle" size="small" ref="invoiceInput" placeholder="请输入开票名称" class="inline-input" @focus="handleFocus" @input="handleInput" @blur="handleBlur" :trigger-on-focus="true" :fetch-suggestions="querySearch" @select="handleSelect"></el-autocomplete>
              <el-button size="small" v-bind:icon='isSelectShow ? "el-icon-arrow-up" : "el-icon-arrow-down"' @click="handleClick" id="dropBtn" class="dropBtn"></el-button>
            </div>
          </el-form-item>
          <el-form-item label="税号" prop="invoiceTaxpayerNo">
            <el-input v-model.trim="invoiceInfo.invoiceTaxpayerNo" size="small" placeholder="请输入税号"></el-input>
          </el-form-item>
          <el-form-item label="注册地址">
            <el-input v-model="invoiceInfo.registeredAddress" size="small" placeholder="请输入单位地址"></el-input>
          </el-form-item>
          <el-form-item label="电话">
            <el-input v-model="invoiceInfo.phone" size="small" placeholder="请输入电话"></el-input>
          </el-form-item>
          <el-form-item label="开户银行">
            <el-input v-model="invoiceInfo.bank" size="small" placeholder="请输入开户银行"></el-input>
          </el-form-item>
          <el-form-item label="银行账户">
            <el-input v-model="invoiceInfo.bankAccount" size="small" placeholder="请输入银行账户"></el-input>
          </el-form-item>

          <el-form-item label="收票人手机">
            <el-input v-model.trim="invoiceInfo.mobilePhone" size="small" placeholder="请输入收票人手机"></el-input>
          </el-form-item>
          <el-form-item label="收票人邮箱">
            <el-input v-model.trim="invoiceInfo.invoiceEmail" size="small" placeholder="请输入收票人邮箱"></el-input>
          </el-form-item>

        </el-form>
        <el-form v-else class="form-class" :label-position="labelPosition" ref="personal" label-width="100px" :rules="rules" :model="invoiceInfo">
          <el-form-item label="收票人手机" prop="mobilePhone">
            <el-input v-model.trim="invoiceInfo.mobilePhone" size="small" placeholder="请输入收票人手机"></el-input>
          </el-form-item>
          <el-form-item label="收票人邮箱" prop="invoiceEmail">
            <el-input v-model.trim="invoiceInfo.invoiceEmail" size="small" placeholder="请输入收票人邮箱"></el-input>
          </el-form-item>
        </el-form>

        <div class="button-container">
          <el-button @click="submitForm()" class="confirm-button" size="medium">确认</el-button>
        </div>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import toast from "./toast.vue";
import API from "@/common/axios";
import Trace from '@/common/trace';
export default {
  props: ['isQWWeb'],
  components: { toast },
  mounted() {
    // this.customerId = "143732633"
    this.initData(0);
  },
  updated() {
    this.updateHeight();
  },
  watch: {
    invoiceInfo: {
      deep: true, // 深度观察对象
      handler(newValue, oldValue) {
        // 当 myObject 发生变化时执行的操作
        console.log(newValue);
        console.log(oldValue);
        if (newValue.invoiceTitle == "个人" && newValue.invoiceType == 1) {
          newValue.invoiceTitle = "";
        }
      }
    },
  },
  data() {
    return {
      customerId: this.$parent.uid, //用户ID
      invoiceId: null,//页面保存的发票id
      invoiceTitleLable: "", //发票浮层点击保存后的开票公司
      show: false,
      select: "1",
      isPersonalFoucus: true,
      isSelectShow: false, //是否展示下拉框
      labelPosition: 'right',
      invoiceInfo: {
        invoiceType: "", //发票类型：0-个人，1-公司
        invoiceTitle: "个人",// 公司名称。
        invoiceTaxpayerNo: "", //发票纳税人识别号
        registeredAddress: "", //注册地址
        phone: "",  //电话
        mobilePhone: "", //发票人手机号码
        invoiceEmail: "", //发票人邮箱
        bank: "", //开户银行
        bankAccount: "",//银行账号
        select: '',
        id: '',//发票id
      },
      rules: {
        invoiceTitle: [
          { required: true, message: '请输入必填项', trigger: 'blur' }
        ],
        invoiceTaxpayerNo: [
          { required: true, trigger: 'blur', validator: this.validateTaxNo }
        ],
        mobilePhone: [
          { required: true, trigger: 'blur', validator: this.validateTel }
        ],
        invoiceEmail: [
          { required: true, message: '请输入必填项', trigger: 'blur' }
        ],
      },
    };
  },

  methods: {
    updateHeight() {
      const div1 = this.$el.querySelector('.el-input__inner');
      const div2 = this.$el.querySelector('.dropBtn');
      if (div1 && div2) {
        div2.style.height = div1.offsetHeight + 'px';
      }
    },
    validateTaxNo: (rule, value, callback) => {
      const validateTaxNoDigits = /^[0-9A-Z]+$/;
      if (value && validateTaxNoDigits.test(value) && (value.length === 15 || value.length === 18)) {
        callback();
      } else if (!(value.length === 15 || value.length === 18)) {
        callback(new Error("纳税人识别号位数有误，请准确填写"));
      } else {
        callback(new Error("纳税人识别号只能输入数字或大写字母"));
      }
    },
    validateTel: (rule, value, callback) => {
      const validateMobileL = /^\d{11}$/;
      if (value && validateMobileL.test(value)) {
        callback();
      } else {
        callback(new Error("手机号格式错误,请重新输入"));
      }
    },
    /**
     * 校验手机号码是11位，并且是数字
     * @param mobile
     * @returns {boolean}
     */
    validateMobileL: function (mobile) {
      const regex = /^\d{11}$/;
      return regex.test(mobile);
    },
    /**
     * 校验发票位数必须是数字或者大写字母
     * @param str
     * @returns {boolean}
     */
    validateTaxNoDigits: function (str) {
      const regex = /^[0-9A-Z]+$/;
      return regex.test(str);
    },

    /**
     * 校验位数必须是15位或者18位
     * @param str
     * @returns {boolean}
     */
    validateTaxNoLength: function (str) {
      return str.length === 15 || str.length === 18;
    },
    handleFocus(e) {
      this.$refs.invoiceInput.suggestionDisabled = true;
      this.$refs.invoiceInput.activated = false;
    },
    handleBlur(e) {
    },
    handleInput(e) {
      // if(this.invoiceInfo.invoiceTitle.trim()) {
      //   this.$refs.invoiceInput.suggestionDisabled = false;
      //   this.$refs.invoiceInput.activated = true;
      // }
    },
    //下拉箭头点击
    handleClick(e) {
      //已经展示，需要隐藏
      let _this = this;
      if (_this.isSelectShow) {
        _this.$refs.invoiceInput.handleBlur(e);
      } else {
        //从by库搜索开票公司
        _this.searchFromBy = true;
        if (_this.$refs.invoiceInput) {
          _this.$refs.invoiceInput.activated = true;
          _this.$refs.invoiceInput.suggestionDisabled = false;
          _this.$refs.invoiceInput.handleFocus(e);
          _this.$refs.invoiceInput.handleInput(this.invoiceInfo.invoiceTitle);
        }
      }
      this.isSelectShow = !this.isSelectShow;
    },
    //弹框关闭监听
    handleClose() {
      this.hideModal();
    },
    dialogClick(e) {
      let className = e.srcElement.getAttribute('class');
      if (className && !(className.includes("el-icon-arrow-up") || className.includes("dropBtn"))) {
        this.isSelectShow = false;
      }
    },
    handleSelect(item) {
      this.isSelectShow = false;
      //如果存在税号直接使用
      if (item.invoiceTaxpayerNo) {
        for (let key in item) {
          this.invoiceInfo[key] = item[key];
        }
        // //回显公司为1时，置空mobilePhone,invoiceEmail
        // //如果是公司，将手机号码和邮箱置空。
        // if(this.invoiceInfo.invoiceType==1){
        //   this.invoiceInfo.mobilePhone ="";
        //   this.invoiceInfo.invoiceEmail="";
        // }
        // if(this.invoiceInfo.invoiceType==1){
        //   this.invoiceInfo.mobilePhone ="";
        //   this.invoiceInfo.invoiceEmail="";
        // }else { //个人时，只提交手机号码和邮箱，类型，其余置空
        //   this.invoiceInfo.invoiceTaxpayerNo = "";
        //   this.invoiceInfo.phone = "";
        //   this.invoiceInfo.bank = "";
        //   this.invoiceInfo.bankAccount = "";
        //   this.invoiceInfo.id = "";
        // }
        return;
      }
      //查询具体的税务信息
      API({
        url: "/invoice/getInvoiceInfo.do",
        data: {
          companyName: item.value
        },
        cancelToken: new API.CancelToken(c => this.cancelRequestToken = c)
      }).then(res => {
        if (res.success == 0) {
          this.$message.error(res.errMsg);
          return;
        } else {
          this.invoiceInfo.invoiceTitle = res.data.companyName;
          this.invoiceInfo.invoiceTaxpayerNo = res.data.creditCode;
          this.invoiceInfo.bank = res.data.bank;
          this.invoiceInfo.bankAccount = res.data.bankAccount;
          this.invoiceInfo.phone = res.data.tel;
          this.invoiceInfo.registeredAddress = res.data.address;
        }
      });
    },
    querySearch(queryString, cb) {
      let _this = this;
      //从by库直接用户有历史开票信息
      if (this.searchFromBy) {
        API({
          url: "/preFillInvoice/getInvoiceInfo.do",
          data: {
            customerId: this.customerId,
            invoiceType: 1
          },
          cancelToken: new API.CancelToken(c => this.cancelRequestToken = c)
        }).then(res => {
          if (res.success == 0) {
            this.$message.error(res.errMsg);
            this.$refs.invoiceInput.suggestionDisabled = true;
            this.$refs.invoiceInput.activated = false;
            return;
          } else {
            //预填写发票记录列表（仅包含发票类型为公司类型的记录，同一个主体公司取最近一条发票记录返回）
            let result = res.data.prefillInvoiceRecordDTOS.map(item => ({
              ...item,
              value: item.invoiceTitle
            }));
            _this.$refs.invoiceInput.suggestionDisabled = false;
            _this.$refs.invoiceInput.activated = true;
            cb(result);
          }
        }).finally(_ => this.searchFromBy = false);
      } else {
        let _this = this;
        clearTimeout(this.timer);
        let timer = setTimeout(() => {
          console.log(_this.invoiceInfo.invoiceTitle);
          //小于两个字不调用联想
          if (_this.invoiceInfo.invoiceTitle.length < 2) {
            _this.$refs.invoiceInput.suggestionDisabled = true;
            _this.$refs.invoiceInput.activated = false;
            cb([]);
            return;
          }
          // 在这里进行联想查询的逻辑
          API({
            url: "/invoice/getCompanyNameList.do",
            data: {
              searchName: _this.invoiceInfo.invoiceTitle,
              caller: "msi.biyao.com",
              operator: _this.$parent.userId,
            },
            cancelToken: new API.CancelToken(c => this.cancelRequestToken = c)
          }).then(res => {
            if (res.success == 0) {
              this.$message.error(res.errMsg);
              _this.$refs.invoiceInput.suggestionDisabled = true;
              _this.$refs.invoiceInput.activated = false;
              return;
            } else {
              let result = res.data.map(item => ({ value: item }));
              _this.$refs.invoiceInput.suggestionDisabled = false;
              _this.$refs.invoiceInput.activated = true;
              cb(result);
            }
          });
        }, 1000);
        this.timer = timer;
      }
    },
    createFilter(queryString) {
      return (companyList) => {
        return (companyList.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    showModal() {
      this.show = true;
      // this.$nextTick(_ => {
      //   _this.listenSpinneShow(_this);
      // });
      Trace({
        operationType: 13
      });
    },
    hideModal() {
      this.show = false;
    },
    submitForm(formName) {
      Trace({
        operationType: 14
      });
      let _this = this;
      //如果包含脱敏信息需要还原
      if (_this.invoiceInfo.mobilePhone && _this.invoiceInfo.mobilePhone == this.formatterPhone(_this.invoiceInfo.mobilePhoneOrigin)) {
        _this.invoiceInfo.mobilePhone = _this.invoiceInfo.mobilePhoneOrigin;
      }
      if (this.invoiceInfo.invoiceType == 0) {
        _this.$refs['personal'].validate((valid, fields) => {
          if (valid) {
            _this.submitData();
            _this.hideModal();
          } else {
            var errormessage;
            for (const [key, value] of Object.entries(fields)) {
              errormessage = value[0].message;
            }
            _this.$message.error(errormessage);
            return false;
          }
        });
      } else {
        _this.$refs['company'].validate((valid, fields) => {
          if (valid) {
            _this.submitData();
            _this.hideModal();
          } else {
            var errormessage;
            for (const [key, value] of Object.entries(fields)) {
              errormessage = value[0].message;
            }
            _this.$message.error(errormessage);
            return false;
          }
        });
      }
    },
    /**
     * 深拷贝对象
     * @param obj
     * @returns {*|*[]|{}}
     */
    deepCopy: function (obj) {
      if (typeof obj !== 'object' || obj === null) {
        return obj;
      }
      const newObj = Array.isArray(obj) ? [] : {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          newObj[key] = this.deepCopy(obj[key]);
        }
      }
      return newObj;
    },
    submitData() {
      let _this = this;
      if (this.invoiceInfo.invoiceType == 0) {
        this.invoiceInfo.invoiceTitle = "个人";
      }
      let params = this.deepCopy(this.invoiceInfo);
      //当为公司1时，保留手机号码和邮箱。
      if (params.invoiceType == 1) {
        // params.mobilePhone ="";
        // params.invoiceEmail="";
      } else { //个人时，只提交手机号码和邮箱，类型，其余置空
        params.invoiceTaxpayerNo = "";
        params.registeredAddress = "";
        params.phone = "";
        params.bank = "";
        params.bankAccount = "";
        params.id = "";
      }
      if (this.isQWWeb) {
        params.createBy = this.$parent.selectUserInfo.xbjId || "";
      }
      API({
        url: "/preFillInvoice/insertInvoice.do",
        data: params,
        cancelToken: new API.CancelToken(c => this.cancelRequestToken = c)
      }).then(res => {
        if (res.success == 0) {
          _this.$message.error(res.errMsg);
          return;
        } else {
          _this.invoiceId = res.data;
          _this.invoiceTitleLable = this.invoiceInfo.invoiceTitle;
          _this.hideModal();
          _this.$message.success('保存成功');
        }
      }).finally(_ => {
      });
    },
    typeChange(invoiceType) {
      this.invoiceInfo.invoiceType = invoiceType;
      if (invoiceType == 0) {
        this.isPersonalFoucus = true;
      } else {
        this.isPersonalFoucus = false;
        if (this.$refs.invoiceInput) {
          this.$refs.invoiceInput.activated = true;
          this.$refs.invoiceInput.suggestionDisabled = false;
        }
      }
      // //获取默认数据
      // this.initData(this.invoiceInfo.invoiceType)
    },
    //获取最近的发票信息
    initData(invoiceType) {
      let _this = this;
      API({
        url: "/preFillInvoice/getInvoiceInfo.do",
        data: {
          invoiceType: invoiceType,
          customerId: this.customerId,
        },
        cancelToken: new API.CancelToken(c => this.cancelRequestToken = c)
      }).then(res => {
        if (res.success == 0) {
          return;
        } else {
          _this.invoiceInfo = res.data.customerAppInvoiceDTO;
          //手机号脱敏
          if (_this.invoiceInfo.mobilePhone) {
            _this.invoiceInfo.mobilePhoneOrigin = _this.invoiceInfo.mobilePhone;
            _this.invoiceInfo.mobilePhone = _this.formatterPhone(_this.invoiceInfo.mobilePhone);
          }
          _this.typeChange(this.invoiceInfo.invoiceType);
          _this.invoiceTitleLable = _this.invoiceInfo.invoiceTitle;
          // //如果是公司，将手机号码和邮箱置空。
          // if(_this.invoiceInfo.invoiceType==1){
          //   _this.invoiceInfo.mobilePhone ="";
          //   _this.invoiceInfo.invoiceEmail="";
          // }else { //个人时，只提交手机号码和邮箱，类型，其余置空
          //   _this.invoiceInfo.invoiceTaxpayerNo = "";
          //   _this.invoiceInfo.phone = "";
          //   _this.invoiceInfo.bank = "";
          //   _this.invoiceInfo.bankAccount = "";
          //   _this.invoiceInfo.id = "";
          // }

        }
      }).finally(_ => {
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    formatterPhone(value) {
      if (value) {
        return value.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2');
      }
      return value;
    }
  }
};
</script>

<style lang="scss" scoped>
.select_title_tx {
  display: flex;
  font-size: 14px;
  color: #333;
  align-items: center;
  height: 40px;
  padding-left: 10px;
  font-weight: bold;
  border-bottom: 1px solid var(--boder-color);

  .content_tx {
    color: #666;
    margin-left: 10px;
    font-weight: normal;
    cursor: pointer;
  }

  i {
    margin-left: 4px;
  }
}

.modal-content {
  background-color: #fefefe;
}


.button-container {
  display: flex;
  justify-content: center;
}

.confirm-button {
  width: 20vw;
  margin-top: 30px;
  border: 2px solid #409EFF;
  border-radius: 4px;
  background: #409EFF;
  color: white;
}

.btn-cornner {
  border-radius: 1px;
}


.form-class {
  margin-top: 10px;
}

.company-input-item {
  width: 100%;
  display: flex;
  align-items: center;

}

.inline-input {
  flex-grow: 1;
}

.dropBtn {
  flex-shrink: 1;
}
</style>