<template>
  <div class="remarks_bg_view">
    <span style="flex-shrink: 0;">订单备注</span>
    <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 2 }" placeholder="请输入内容" v-model="inputValue" @input="inputChang" maxlength="50" show-word-limit @focus="inputFocus">
    </el-input>
  </div>
</template>

<script>
import Trace from '@/common/trace';
export default {
  data() {
    return {
      inputValue: ""
    };
  },
  methods: {
    inputChang(e) {
      this.inputValue = e ? e.trim() : "";
      this.$parent.submitVo.remarksTx = this.inputValue || "";
    },
    inputFocus() {
      Trace({
        operationType: 15
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.remarks_bg_view {
  font-size: 14px;
  border-bottom: 1px solid var(--boder-color);
  display: flex;
  line-height: 20px;
  font-weight: bold;
  padding: 10px;
  color: #333;

  /deep/ .el-textarea {
    margin: 0 10px;

    textarea {
      resize: none !important;
      color: #409EFF;
      word-break: break-all;
      text-align: justify;
    }
  }
}
</style>