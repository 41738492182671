<template>
  <div class="content_view">
    <el-image class="p_img" :src="getProductImg" alt=""></el-image>
    <div class="tx_view">
      <div class="name_tx">{{ productInfo.productName }}</div>
      <div class="su_tx">{{ getSuText }}</div>
    </div>
    <div class="price_view">
      <div class="price_tx">{{ getPriceTx }}</div>
      <div class="d_price_tx">{{ getDiscountPriceTx }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['productInfo', 'submitVo'],
  computed: {
    // 已选sku 文本
    getSuText() {
      let str = '已选择：';
      if (!this.submitVo.suId) {
        str += '无';
      } else {
        let skuOb = this.productInfo.sizeDetail.find(o => o.suId == this.submitVo.suId);
        skuOb.specs.slice().sort((a, b) => a.specsCateId - b.specsCateId).forEach(o => {
          str += `(${o.name}:${o.des}) `;
        });
        str += `  x${this.submitVo.num}`;
      }
      return str;
    },
    // 单买价文案
    getPriceTx() {
      let str = '单买价：';
      if (!this.submitVo.suId) {
        str += '无';
      } else {
        let skuOb = this.productInfo.sizeDetail.find(o => o.suId == this.submitVo.suId);
        str += `￥${parseFloat(skuOb.priceStr)}`;
      }
      return str;
    },
    getDiscountPriceTx() {
      let skuOb = this.productInfo.sizeDetail.find(o => o.suId == this.submitVo.suId);
      if (!this.submitVo.discountInfo) {
        return "";
      }
      let priceNum = parseFloat(parseFloat(skuOb.priceStr - this.submitVo.discountInfo.allowancePrice / this.submitVo.num).toFixed(2));
      if (priceNum < 0) {
        priceNum = 0;
      }
      return `优惠价：￥${priceNum}`;
    },
    getProductImg() {
      if (!this.submitVo.suId) {
        return this.productInfo.imgList[0].smallImg;
      }
      let skuOb = this.productInfo.sizeDetail.find(o => o.suId == this.submitVo.suId);
      return skuOb.smallConfirmImg;
    }
  }
};
</script>

<style lang="scss" scoped>
.content_view {
  padding: 10px;
  display: flex;
  background-color: var(--boder-color);
  width: 100%;
  box-sizing: border-box;

  .p_img {
    width: 60px;
    height: 60px;
    border-radius: 10px;
    font-size: 0;
  }

  .tx_view {
    margin-left: 10px;
    max-width: calc(100% - 190px);
    line-height: 20px;
    text-align: justify;

    .name_tx {
      font-size: 14px;
      color: #333;
      margin-top: 4px;
    }

    .su_tx {
      font-size: 12px;
      color: #666;
      margin-top: 4px;
    }
  }

  .price_view {
    margin-right: 10px;
    margin-left: auto;
    line-height: 20px;
    text-align: right;

    .price_tx {
      font-size: 13px;
      color: #999;
      margin-top: 4px;
    }

    .d_price_tx {
      font-size: 12px;
      color: red;
      margin-top: 4px;
    }
  }
}
</style>